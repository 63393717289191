<template>
	<div class="bar">
		<router-link
			class="bar-item"
			:class="ItemClassGet(index)"
			:to="btn.link"
			:key="id + btn.id"
			v-for="(btn, index) in btns"
		>
			{{ btn.text }}
		</router-link>
	</div>
</template>

<script>
export default {
	name: 'SideBar',
	props: {
		id: {
			type: String,
			default() {
				return '';
			},
		},
		btns: {
			type: Array,
			default() {
				return [];
			},
		},
		value: {
			type: String,
			default() {
				return '';
			},
		},
	},
	data() {
		return {};
	},
	computed: {
		activeIndex() {
			return this.btns.findIndex((btn) => btn.id === this.value);
		},
	},
	methods: {
		ItemClassGet(index) {
			return {
				'bar-item-active': this.activeIndex === index,
			};
		},
	},
};
</script>

<style scoped>
.bar {
	width: 100%;
	margin: 0.5rem;
	display: flex;
	flex-direction: column;
	border-radius: 0.75rem;
	border: solid 0.025rem #cccccc;
	overflow: hidden;
}
.bar-item {
	width: 100%;
	padding: 0.75rem 1rem;
	color: #2c3e50;
	font-weight: bold;
	text-decoration: none;
}
.bar-item:nth-child(n + 2) {
	border-top: solid 0.025rem #cccccc;
}
.bar-item:hover {
	color: #ffffff;
	background-color: #03c967;
}
.bar-item.bar-item-active {
	color: #ffffff;
	background-color: #ff5722;
}
.title {
	color: #ff5722;
	font-weight: bold;
}

.link-text {
	color: #03c967;
}
</style>
