<template>
	<div class="about">
		<div class="container-fluid">
			<div class="row">
				<div class="col-12 col-lg-3 mb-5">
					<SideBar
						id="tmtAboutSideBar"
						:btns="btns"
						:value="contentId"
					></SideBar>
				</div>
				<div class="col-12 col-lg-9">
					<div class="container-fluid" v-if="contentId === 'history'">
						<div class="row mb-3">
							<div class="col">
								<h1 class="text-bold">公司沿革</h1>
							</div>
						</div>
						<div class="row">
							<div class="col">
								<p>
									<span>
										得美特科技從「工業4.0數據與控制工程」出發，在
									</span>
									<span class="text-bold">
										「工業控制技術」
									</span>
									<span>
										與
									</span>
									<span class="text-bold">
										「工業無線數據傳輸」
									</span>
									<span>
										等領域累積深厚的創新技術與實務經驗
									</span>
								</p>
								<p>
									<span>
										2018年開始將創新技術應用於智慧大樓自動化控制領域，並與相關產、官、學合作創新建立
									</span>
									<span class="text-bold">
										「微氣候能量模型」
									</span>
									<span>
										，突破專業Know-How能力
									</span>
								</p>
								<p>
									<span>
										2019年推出業界首創
									</span>
									<span class="text-bold text-highlight">
										「微氣候能量AI控制神經系統」
									</span>
									<span>
										獲得
									</span>
									<span class="text-bold text-highlight">
										經濟部AI國際選秀賽創新特優獎
									</span>
									<span>
										，與獲得
									</span>
									<span class="text-bold">
										聯合報總部
									</span>
									<span>與</span>
									<span class="text-bold">
										林口科技大廠
									</span>
									<span>
										之專案設計與導入
									</span>
								</p>
								<p>
									<span>
										2020正式上線
									</span>
									<span class="text-highlight">
										全國第一座「AI控制神經系統」大型智慧建築「聯合報汐止總部」
									</span>
									<span>
										並以工業大數據工程技術，協助國內頂級抗老醫學中心安法診所，建置
									</span>
									<span class="text-bold">
										「醫療大數據ERP系統」
									</span>
								</p>
							</div>
						</div>
						<div class="row">
							<div class="col">
								<img
									class="w-100"
									src="../assets/about_01_01.png"
									alt="tomatoTEK history"
								/>
							</div>
						</div>
					</div>

					<div class="container-fluid" v-if="contentId === 'team'">
						<div class="row mb-3">
							<div class="col">
								<h1 class="text-bold">技術專家團隊</h1>
							</div>
						</div>
						<div class="row">
							<div class="col">
								<p>
									<span>
										得美特科技管理團隊成員來自微軟、英特爾、施耐德、廣達、研華科技等國際科技公司，以
									</span>
									<span class="text-bold">
										Design Thinking
									</span>
									<span>
										的團隊文化建立
									</span>
									<span class="text-bold text-highlight">
										Empower Green Planet
									</span>
									<span>
										的企業願景
									</span>
								</p>
								<p>
									得美特科技自主研發之核心系統包含「工業大數據倉儲」、「工業無線數據神經系統」與「工業AI控制系統」等三大領域。應用於「中央空調AI神經系統」到「醫療大數據ERP」等跨不同產業領域，配合產業Know-How協助業主創新轉型，掌握大數據分析與AI操控策略的競爭力
								</p>
								<p>
									為深入解決大型中央空調複雜的系統問題，得美特科技與國內知名產業與學界合作創新設計「微氣候能量系統」，將「神經數據電腦」、「神經控制器」、「WINDS雲端AI控制系統」等深度領域技術與產品，透過「人工智慧數據化工程」顧問服務，為客戶解決系統性的專業問題，與客戶發展長期夥伴關係，創造雙贏的客戶價值
								</p>
							</div>
						</div>
					</div>

					<div
						class="container-fluid"
						v-if="contentId === 'ai-data-engineering-design'"
					>
						<div class="row mb-3">
							<div class="col">
								<h1 class="text-bold">人工智慧數據工程設計</h1>
							</div>
						</div>
						<div class="row">
							<div class="col">
								<p>
									得美特專家團隊以專案模式提供「AI控制數據工程」顧問與系統建置工程服務
								</p>
							</div>
						</div>
						<div class="row justify-content-center">
							<div class="col-10">
								<img class="w-100" src="../assets/about_03_01.png" />
							</div>
						</div>
						<div class="row mt-3">
							<div class="col">
								<p>
									<span>
										微氣候數據工程服務先從
									</span>
									<span class="text-bold">
										「系統診斷分析」
									</span>
									<span>
										評估業主系統現況與需求可行性分析
									</span>
								</p>
								<p>
									<span class="text-bold">
										「系統規畫設計」
									</span>
									<span>
										依業主現階段需求，與未來擴充需要，提供提設計提案
									</span>
								</p>
								<p>
									<span class="text-bold">
										「系統建置維護」
									</span>
									<span>
										依設計提案內容，由得美特科技提供完整軟體與硬體解決方案，依工程規畫進度施工，並於驗收後與業主簽定系統維護服務合約，確保系統長期運行順暢
									</span>
								</p>
								<p>
									<span class="text-bold">
										「系統升級擴充」
									</span>
									<span>
										當業主企業成長之需求擴充或設備老舊汱換之升級規劃等專業服務
									</span>
								</p>
							</div>
						</div>
						<div class="row justify-content-center">
							<div class="col-10">
								<img class="w-100" src="../assets/about_03_02.png" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import SideBar from '@/components/SideBar.vue';

export default {
	name: 'About',
	components: {
		SideBar,
	},
	data() {
		return {
			contentId: '',
			btns: [
				{
					id: 'history',
					text: '公司沿革',
					link: '/about/history',
				},
				{
					id: 'team',
					text: '技術專家團隊',
					link: '/about/team',
				},
				{
					id: 'ai-data-engineering-design',
					text: '人工智慧數據工程設計',
					link: '/about/ai-data-engineering-design',
				},
			],
		};
	},
	computed: {
		contentIds() {
			return this.btns.map((btn) => btn.id);
		},
	},
	methods: {
		ContentIdReload(target) {
			let result = this.contentIds[0];
			if (this.contentIds.includes(target)) {
				result = target;
			}
			this.contentId = result;
		},
	},
	watch: {
		$route() {
			this.ContentIdReload(this.$route.params.name_id);
		},
	},
	created() {
		this.ContentIdReload(this.$route.params.name_id);
	},
};
</script>

<style scoped>
h1 {
	color: rgb(45, 177, 96);
	font-size: 1.75rem;
}
.about {
	font-size: 1.25rem;
}
.text-bold {
	font-weight: bold;
}
.text-highlight {
	color: rgb(52, 172, 139);
}
</style>
